import React, { useState, Dispatch, SetStateAction, ReactFileReader } from "react";
import { TezosToolkit, OpKind, MichelsonMap } from "@taquito/taquito";
import "./App.css";
import ConnectButton from "./components/ConnectWallet";
import {NetworkType} from "@airgap/beacon-sdk";
import { BeaconWallet } from "@taquito/beacon-wallet";

import { card_data } from "./data";
import { price_data } from "./prices"
import { useEffect } from "react";
import { presale_data } from "./presale";
import { supply_data } from "./supply";
import { token_to_name } from "./token_to_name";
import { codebook } from "./codebook";

import MintButton from "./components/Minter";

enum BeaconConnection {
  NONE = "",
  LISTENING = "Listening to P2P channel",
  CONNECTED = "Channel connected",
  PERMISSION_REQUEST_SENT = "Permission request sent, waiting for response",
  PERMISSION_REQUEST_SUCCESS = "Wallet is connected"
}


interface CardParam {
  value: string;
  values: string[];
  setValue: Dispatch<SetStateAction<any>>,
}

const Card = ({
  value,
  values,
  setValue,
}: CardParam) => {

  const callback = (e: React.FormEvent<HTMLInputElement>): void => {
    setValue( e.currentTarget.value );
  };

  var options = [];
  for (var i=0; i<values.length; i++) {
    var pretty = values[i].replace(/_/g," ").replace(" plus "," + ");
    options.push(<option key={i} value={values[i]}>{pretty}</option>);
  }
  var path = "https://raw.githubusercontent.com/llgllgllg/cards/main/small_v2/" + value + ".png";
  return (
    <div className="card-select">
      <div>
        <select value={value} onChange={callback}>
          {options}
        </select>
        <div> 
          <img src={path} />
        </div>
      </div>
    </div>
  );
};

interface MinterParam {
  Tezos: any
  value1: any
  setValue1: any
  value2: any
  setValue2: any
  value3: any
  setValue3: any
  value4: any
  setValue4: any
  userAddress: any
  beaconConnection: any
  objkt_contract: any
  contract: any
  admin_address: any
  adminMode: any
}

const Minter = ({
  Tezos,
  value1,
  setValue1,
  value2,
  setValue2,
  value3,
  setValue3,
  value4,
  setValue4,
  userAddress,
  beaconConnection,
  objkt_contract,
  contract,
  admin_address,
  adminMode
}: MinterParam) => {

  // declare values
  // need map from tokens to titles
  const [values1,setValues1] = useState<string[]>([]);
  const [values2,setValues2] = useState<string[]>([]);
  const [values3,setValues3] = useState<string[]>([]);
  const [values4,setValues4] = useState<string[]>([]);
  const [userTokens,setUserTokens] = useState<any>({});
  const [mintMessage,setMintMessage] = useState<any>("");

  function build_options(values) {
    var options = [];
    for (var i=0; i<values.length; i++) {
      var pretty = values[i].replace(/_/g," ").replace(" plus "," + ");
      options.push(<option key={i} value={values[i]}>{pretty}</option>);
    }
    return options;
  };

  const callback1 = (e: React.FormEvent<HTMLInputElement>): void => {
    setValue1( e.currentTarget.value );
  };
  const callback2 = (e: React.FormEvent<HTMLInputElement>): void => {
    setValue2( e.currentTarget.value );
  };
  const callback3 = (e: React.FormEvent<HTMLInputElement>): void => {
    setValue3( e.currentTarget.value );
  };
  const callback4 = (e: React.FormEvent<HTMLInputElement>): void => {
    setValue4( e.currentTarget.value );
  };

  


  const loadTokenData = (): void => {
    console.log(beaconConnection);
    if (beaconConnection) {

      console.log(userAddress);

      //const url = 'https://api.granadanet.tzkt.io/v1/contracts/' + objkt_contract + '/bigmaps/ledger/keys?key.address=' + userAddress + '&key.nat.gt=377956&key.nat.lt=378534&limit=1000';
      //const temp_address = "tz1RMthfMEDJsXiMqz4Gy4DDx7utzYVNP14c";
      const url = 'https://api.tzkt.io/v1/contracts/' + objkt_contract + '/bigmaps/ledger/keys?key.address=' + userAddress + '&key.nat.gt=377956&key.nat.lt=378534&limit=1000';
    
      fetch(url).then(res => {
        return res.json()
      }).then(res => {
        var tmp_cards = {};
        var tmp_values1 = [];
        var tmp_values2 = [];
        var tmp_values3 = [];
        var tmp_values4 = [];
        for (var i=0; i<res.length; i++) {
          if (parseFloat(res[i]["value"]) > 0) {
            var name = token_to_name[res[i]["key"]["nat"]];
            if (card_data["base"].includes(name)) {
              tmp_values1.push( name );
            }
            if (card_data["ops"].includes(name)) {
              tmp_values2.push( name );
              tmp_values3.push( name );
            }
            if (card_data["color"].includes(name)) {
              tmp_values4.push( name );
            }
            tmp_cards[name] = parseFloat(res[i]["value"]);
          }
        }

        if (Object.keys(tmp_cards).length <= 2) {
          setMintMessage("You need at least 3 cardz to mint an artwork.");
          return;
        }

        tmp_values2.push( "no_card" );
        tmp_values3.push( "no_card" );

        setValues1(tmp_values1);
        setValues2(tmp_values2);
        setValues3(tmp_values3);
        setValues4(tmp_values4);

        setUserTokens( tmp_cards );

        if (tmp_values1.length > 0) {
          setValue1(tmp_values1[0]);
        }
        if (tmp_values2.length > 0) {
          setValue2(tmp_values2[0]);
        }
        if (tmp_values3.length > 0) {
          setValue3(tmp_values3[0]);
        }
        if (tmp_values4.length > 0) {
          setValue4(tmp_values4[0]);
        }

        setMintMessage("");
        
        
      }).catch();
    }
    else {
      setMintMessage("You must sync wallet before loading tokens.")
    }
  };

  
  

  var options1 = build_options(values1);
  var options2 = build_options(values2);
  var options3 = build_options(values3);
  var options4 = build_options(values4);

  // check if the values are valid

  var selections = [value1, value2, value3, value4];

  var messageBox = (
    <div className="main-box">
    </div>
  );

  if (mintMessage !== "") {
    messageBox = (
      <div className="main-box">
        <div className="banner-box">
          {mintMessage}
        </div>
      </div>);
  }

  /*
  var namer = (
    <div className="main-box">
    <span className="big-white-text">Name Your Artwork</span>
    <input className="title-input"></input>
    </div>
  );
  */

  return (
    <div>
      <div className="main-box">
        <span className="white-text">
          THE ARTWORK SHOWN ABOVE IS ONLY A PREVIEW, THE FINAL ARTWORK WILL BE GENERATED USING THE MINT TRANSACTION OPHASH AS A SEED.
        </span>
      </div>
      <div className="main-box">
        <button className="thin-button" onClick={loadTokenData}>
          <span>
            Load Cardz
          </span>
        </button>

        <select className="fat-select" value={value1} onChange={callback1}>
          {options1}
        </select>
        <select className="fat-select" value={value2} onChange={callback2}>
          {options2}
        </select>
        <select className="fat-select" value={value3} onChange={callback3}>
          {options3}
        </select>
        <select className="fat-select" value={value4} onChange={callback4}>
          {options4}
        </select>

        <MintButton
          Tezos={Tezos}
          selections={selections}
          userTokens={userTokens}
          setMintMessage={setMintMessage}
          objkt_contract={objkt_contract}
          contract={contract}
          admin_address={admin_address}
          adminMode={adminMode}
        />
      </div>

      {messageBox}

    </div>
  );
};

interface CardParentParam {
  Tezos: any
  userAddress: any
  beaconConnection: any
  objkt_contract: any
  contract: any
  admin_address: any
  mapData: any
  adminMode: any
}

const CardParent = ({
  Tezos, 
  userAddress, 
  beaconConnection, 
  objkt_contract,
  contract, 
  admin_address,
  mapData,
  adminMode} : CardParentParam) => {

  const [value1,setValue1] = useState<string>(card_data["base"][0]);
  const [value2,setValue2] = useState<string>(card_data["ops"][0]);
  const [value3,setValue3] = useState<string>(card_data["ops"][6]);
  const [value4,setValue4] = useState<string>(card_data["color"][0]);

  function random_element(x) {
    return x[Math.floor(Math.random() * x.length)]
  };
  
  const shuffle = (e: React.FormEvent<HTMLInputElement>): void => {
    setValue1( random_element(card_data["base"]) );
    setValue2( random_element(card_data["ops"]) );
    setValue3( random_element(card_data["ops"]) );
    setValue4( random_element(card_data["color"]) );
  };

  function basename(x) {
    return x.split('/').reverse()[0].split(".")[0];
  };

  //var art_path = "https://raw.githubusercontent.com/llgllgllg/art/main/" + art_data[basename(value1) + "+" + basename(value2) + "+" + basename(value3)];

  var prefix = basename(value1) + "+" + basename(value2) + "+" + basename(value3) + "+" + basename(value4);
  var code = codebook[basename(value1)] + "+" + codebook[basename(value2)] + "+" + codebook[basename(value3)] + "+" + codebook[basename(value4)];
  //var art_path = "https://raw.githubusercontent.com/llgllgllg/" + basename(value4) + "_PNG/main/" + prefix + "_" + art_data_v2[prefix] + ".png";

  var art_path = "";
  if (mapData !== null) {
    var art_path = "https://raw.githubusercontent.com/llgllgllg/" + basename(value4) + "_PNG/main/" + prefix + "_" + mapData[code] + ".png";
  }
  //var art_path = "https://raw.githubusercontent.com/llgllgllg/" + basename(value4) + "_PNG/main/" + prefix + "_" + art_data_small[code] + ".png";

  //console.log(art_path_v2);
  //var color_card = art_path.split("+")[3];
  //color_card = color_card.substring(0,color_card.search(/\d/)-1);

  return (
    <div>
      <div className="explorer-content">
        <div className="card-box">
          <Card
            value={value1}
            values={card_data["base"]}
            setValue={setValue1}
          />
          <Card
            value={value2}
            values={card_data["ops"]}
            setValue={setValue2}
          />
          <Card
            value={value3}
            values={card_data["ops"]}
            setValue={setValue3}
          />
          <Card
            value={value4}
            values={card_data["color"]}
            setValue={setValue4}
          />
        </div>
        <div className="image-box">
          <div className="image-wrap">
            <img className="image-art" src={art_path}></img>
          </div>
        </div>
      </div>
      <div className="header-container">
        <div className="left-header-box">
        </div>
        <div className="right-header-box">
          <button className="plain-button" onClick={shuffle}>
            <span>
              Shuffle
            </span>
          </button>
        </div>
      </div>
      <div className="main-content">
        <Minter
          Tezos={Tezos}
          value1={value1}
          setValue1={setValue1}
          value2={value2}
          setValue2={setValue2}
          value3={value3}
          setValue3={setValue3}
          value4={value4}
          setValue4={setValue4}
          userAddress={userAddress}
          beaconConnection={beaconConnection}
          objkt_contract={objkt_contract}
          contract={contract}
          admin_address={admin_address}
          adminMode={adminMode}
        />
      </div>
    </div>
  );
};


///*
const objkt_contract = "KT1RJ6PbjHpwc3M5rw5s2Nbmefwbuwbdxton";
//const rpcUrl = "https://mainnet.smartpy.io";
//const rpcUrl = "https://mainnet-tezos.giganode.io"
//const rpcUrl = "https://rpc.tzstats.com"
const rpcUrl = "https://mainnet.api.tez.ie"
const networkType = NetworkType.MAINNET;
const contract = "KT1EFBc1XtSAgwgUXeApmetkFXkyHpsB93MH";
//*/

/*
const objkt_contract = "KT1KT4sYahD4uGUkd8ZTjsUXt3bmL5SLXr7M";
const contract = "KT1HG5LmQcKtSbVp52nRMhdGjyqoDywh9SRL";
const rpcUrl = "https://granadanet.smartpy.io";
const networkType = NetworkType.GRANADANET;
*/

const Tezos = new TezosToolkit(rpcUrl);
const wallet = new BeaconWallet({
  name: 'ARTCARDZ',
  preferredNetwork: networkType,
});
Tezos.setWalletProvider(wallet);

const App = () => {

  // make sure to fix ledger link too!!
  //const [Tezos, setTezos] = useState<TezosToolkit>(
  //  new TezosToolkit(rpcUrl)
  //);
  //const [wallet, setWallet] = useState<any>(null);
  const [beaconConnection, setBeaconConnection] = useState<boolean>(false);
  const [userAddress, setUserAddress] = useState<string>("");
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loadedData, setLoadedData] = useState<boolean>(false);
  const [mapData, setMapData] = useState<any>(null);
  //const [supply, setSupply] = useState<string>("1000");
  const [adminMode, setAdminMode] = useState<any>(false);

  var sale_date = "October 6th, 2021 at 6AM GMT";
    

  useEffect( async (): Promise<void> => {

    // lets load data here 
    //const url = 'https://github.com/llgllgllg/site_maps/raw/main/art_v3.json';
    const url = 'https://raw.githubusercontent.com/llgllgllg/site_maps/main/art_v3.json'

    if (loadingData == false) {
      setLoadingData(true);
      fetch(url)
        .then(res => res.json())
        .then(json => {
          setMapData(json);
          setLoadedData(true);
      });
    }    

    const active = await wallet.client.getActiveAccount();
    if (active !== undefined) {
      const userAddress = await wallet.getPKH();
      setUserAddress(userAddress);
      setBeaconConnection(true);
    }

  });
  
  // make a function that loads data
  // and then displays
  

  const collectCard = async (amount): Promise<void> => {
    console.log(amount);
    if (beaconConnection) {
      try {
        let c = await Tezos.wallet.at(contract);
        const op = await c.methods.buy(objkt_contract, amount).send({ amount:price_data[amount], storageLimit: 100*amount });
        await op.confirmation();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const collectPresale = async (): Promise<void> => {
    if (beaconConnection) {
      try {
        var amount = 4;
        if (userAddress in presale_data) {
          amount = presale_data[userAddress];
        }
        let c = await Tezos.wallet.at(contract);
        const op = await c.methods.buy_presale(objkt_contract).send({ amount:price_data[amount], storageLimit: 100*amount });
        await op.confirmation();
      } catch (error) {
        console.log(error);
      }
    }
  };

  var supply_rows = [];
  for (var i=0; i<supply_data.length; i++) {
    var name = "supply_row_" + i.toString();
    supply_rows.push(
      <tr key={name}>
        <td>{supply_data[i]["title"]}</td>
        <td>{supply_data[i]["type"]}</td>
        <td>{supply_data[i]["supply"]}</td>
      </tr>);
  }

  var supply_table = (
    <table>
      <tbody>
        <tr>
          <td>CARD</td>
          <td>CARD TYPE</td>
          <td>EDITIONS</td>
        </tr>
        <tr><td>&nbsp;</td></tr>
        {supply_rows}
      </tbody>
    </table>
  );


  /*
  const supply_widget = (
    <div className="main-box">
      <div className="caption-box">
        <p>
          Cards remaining in batch (refresh to update) : {supply}
        </p>
      </div>
    </div>
  );
  */
  
  const banner = (
    <div className="main-box">
      <div className="banner-box">
        Artwork minting is down at the moment, working to fix the issue.
      </div>
    </div>);
  
  const minting_banner = (
    <div className="main-box">
      <div className="yellow-banner-box">
        <span>
          5000 cardz have sold out! Currently, cardz-holders are minting their unique 1/1 artworks. 
          <br></br>
          To mint yours, use your cardz or purchase them on the secondary (Click Buy Cardz button above).
        </span>
      </div>
    </div>
  );
  
  const sale_note = (
    <div className="main-box">
      <div className="caption-box">
        Only the presale button will work before {sale_date}.
      </div>
    </div>
  );

  const card_sale_box = (
    <div className="main-box">
      <div className="buttons">
        <button className="sale-button" onClick={() => collectCard(1)}>
          <span>
            Collect 1 Card
          </span>
        </button>
        <button className="sale-button" onClick={() => collectCard(4)}>
          <span>
            Collect 4 Cards
          </span>
        </button>
        <button className="sale-button" onClick={() => collectCard(8)}>
          <span>
            Collect 8 Cards
          </span>
        </button>
        <button className="sale-button" onClick={() => collectPresale()}>
          <span>
            Collect Presale
          </span>
        </button>
      </div>
    </div>
  );
  
  var link = "https://tzkt.io/" + contract + "/operations/";
  var ledger_link = "https://tzkt.io/KT1EFBc1XtSAgwgUXeApmetkFXkyHpsB93MH/storage/19142";
  if (networkType == NetworkType.GRANADANET) {
    link = "https://granadanet.tzkt.io/" + contract + "/operations/";
    ledger_link = "https://granadanet.tzkt.io/KT1HG5LmQcKtSbVp52nRMhdGjyqoDywh9SRL/storage/84969";
  }
  

  var example_links = [
    "https://github.com/llgllgllg/examples/raw/main/semicircles%2Bdiagonal_slice_right_plus_random_scale%2Bcooltone_pink_106756394.png",
    "https://github.com/llgllgllg/examples/raw/main/semicircles%2Bdiagonal_slice_right_plus_random_scale%2Bcooltone_pink_1168534423.png",
    "https://github.com/llgllgllg/examples/raw/main/semicircles%2Bdiagonal_slice_right_plus_random_scale%2Bcooltone_pink_505709465.png",
    "https://github.com/llgllgllg/examples/raw/main/semicircles%2Bdiagonal_slice_right_plus_random_scale%2Bcooltone_pink_766881875.png",
    "https://github.com/llgllgllg/examples/raw/main/semicircles%2Bdiagonal_slice_right_plus_random_scale%2Bcooltone_pink_937713321.png"
  ];
  var examples_a = [];
  for (var i=0; i<example_links.length; i++) {
    var name = i.toString() + "example_a";
    examples_a.push((
      <div key={name} className="card-select">
        <a href={example_links[i]} target="_blank">
          <img src={example_links[i]}></img>
        </a>
      </div>
    ));
  }

  var example_links = [
    "https://github.com/llgllgllg/examples/raw/main/circle_grid_small%2Bhorizontal_slice_plus_random_scale%2Brectangle_spin_plus_random_rotate%2Bpastel_dark_blue_1558947880.png",
    "https://github.com/llgllgllg/examples/raw/main/circle_grid_small%2Bhorizontal_slice_plus_random_scale%2Brectangle_spin_plus_random_rotate%2Bpastel_dark_blue_210386568.png",
    "https://github.com/llgllgllg/examples/raw/main/circle_grid_small%2Bhorizontal_slice_plus_random_scale%2Brectangle_spin_plus_random_rotate%2Bpastel_dark_blue_291582611.png",
    "https://github.com/llgllgllg/examples/raw/main/circle_grid_small%2Bhorizontal_slice_plus_random_scale%2Brectangle_spin_plus_random_rotate%2Bpastel_dark_blue_327402353.png",
    "https://github.com/llgllgllg/examples/raw/main/circle_grid_small%2Bhorizontal_slice_plus_random_scale%2Brectangle_spin_plus_random_rotate%2Bpastel_dark_blue_53826554.png"
  ];
  var examples_b = [];
  for (var i=0; i<example_links.length; i++) {
    var name = i.toString() + "example_b";
    examples_b.push((
      <div key={name} className="card-select">
        <a href={example_links[i]} target="_blank">
          <img src={example_links[i]}></img>
        </a>
      </div>
    ));
  }

  function activate_admin_mode () {
    setAdminMode(true);
  }
  
  return (
    <div>
      <div className="main-box">
        <div className="header-container">
          <div className="left-header-box">
            <header>ART CARDZ</header>
          </div>
          <div className="right-header-box">
            <a href="https://discord.gg/ZTbMEe6G9g" target="_blank">
              <img className="discord" src="https://github.com/llgllgllg/logo/raw/main/Discord-Logo-Color.png" ></img>
            </a>
            <button className="header-button">
              <a href="https://objkt.com/profile/tz1X4Ki4K5EqVLZ3KfMYggqSNYaeTFoK15gc/creations" target="_blank">
                Buy Cardz
              </a>
            </button>
            <button className="header-button">
              <a href="https://objkt.com/collection/artcardz" target="_blank">
                Buy ArtWork
              </a>
            </button>
            <div className="spacer"></div>
            <ConnectButton
              Tezos={Tezos}
              setWallet={null}
              setBeaconConnection={setBeaconConnection}
              beaconConnection={beaconConnection}
              setUserAddress={setUserAddress}
              wallet={wallet}
              setTezos={null}
              rpcUrl={rpcUrl}
              networkType={networkType}
              contract={contract}
            />
          </div>
        </div>
      </div>

      {minting_banner}
      
      <div className="main-box">
        <CardParent 
          Tezos={Tezos}
          userAddress={userAddress}
          beaconConnection={beaconConnection}
          objkt_contract={objkt_contract}
          contract={contract}
          admin_address={userAddress}
          mapData={mapData}
          adminMode={adminMode}
        />
      </div>

      <div className="main-box">
        <div className="text-box">
          <h1>How do I mint artworks?</h1>
          <ul className="bulletless-noindent">
            <li>
              1) Sync your wallet.
            </li>
            <li>
              2) Click the "Load Cardz" button to load the tokens in your wallet.
            </li>
            <li>
              3) Select the cardz you want to use in the four drop down menus directly to the right of the Load Cardz button.
            </li>
            <li>
              4) Click mint or mystery mint (for mystery card holders only). You can double check the cards that will be burnt when this operation is applied, by clicking the token ids in the burn warning.
            </li>
            <li>
              <span className="op-color">NOTE : If you are minting several artworks, Load Cardz will need to be pressed each time after the Mint transaction is applied (i.e. cards are burnt), to retrieve updated information on the cardz in your wallet.</span>
            </li>
          </ul>
          <h1>How does minting artworks work technically?</h1>
          <ul className="bulletless-noindent">
            <li>
              1) When you click the mint (or mystery mint) button <a href={link} target="_blank">this</a> smart contract burns the cards that are being used to create an artwork, and creates a new entry on the artwork <a href={ledger_link} target="_blank">ledger</a>. Since there is a cost associated with putting a custom smart contract onto the blockchain (see 3), a step required for each artwork, this operation costs 0.25 tez.
            </li>
            <li>
              2) The artwork is generated offline using the ophash of the mint operation.
            </li>
            <li>
              3) A custom royalty contract is created (one contract per artwork), which will receive 10% royalties on each sale. The contract automatically distributes 25% of that amount to you (i.e. 2.5% of the total sale amount).
            </li>
            <li>
              4) Then the generated artwork is minted on the <a href="https://tzkt.io/KT1LbLNTTPoLgpumACCBFJzBEHDiEUqNxz5C/operations/" target="_blank">Art Cardz FA2 contract</a>, with the custom royalty contract stored in the <a href="https://tzkt.io/KT1LbLNTTPoLgpumACCBFJzBEHDiEUqNxz5C/storage/19086" target="_blank">token metadata</a>.
            </li>
            <li>
              <span className="op-color">NOTE : Due to a variety of potential issues including but not limited to: backlogs, computer crashes and power outages, there may be delays before your artwork is minted and viewable in your wallet. Rest assured, if you are on the artwork <a href={ledger_link} target="_blank">ledger</a>, your artwork will be minted ASAP.</span>
            </li>
          </ul>
          <h1>In what order will artworks be minted?</h1>
          <p>
            The artworks will be minted in the order they appear on the artwork ledger. In other words, first come first serve.
          </p>
          <h1>What difference does a seed make?</h1>
          <p>
            Some examples using the same cards with different seeds. Click on an image to see it larger.
          </p>
          <div className="card-box">
            {examples_a}
          </div>
          <div className="card-box">
            {examples_b}
          </div>
          <h1>What is Art Cardz?</h1>
          <p>
            Art Cardz is a collection of cards on the Tezos blockchain, which can be collected and used to mint unique 1/1 artworks. In contrast to most generative art projects where collectors have limited control over the artwork they mint, Art Cardz allows collectors to customize their own generative algorithm! You can explore the possibilities using the interface above.
          </p>
          <p>
            There are 56 different cards, and 136,900 possible ways to combine them. Since each generated artwork is seeded with a unique random seed, the possibilities are nearly limitless! In addition, there are <span className="op-color">5 super rare</span> mystery cards.
          </p>
          <h1>What do other people say about Art Cardz?</h1>
          <ul className="bulletless-noindent">
            <li>Seems it was just a few weeks ago that a 1/1 selling for 11 tez was a big deal, but looks like you’ve gone light years beyond that with the new artcard<span className="op-color">z</span>, wow way to go! - <a href="https://twitter.com/chankfonts" target="_blank">Chank Diesel</a></li>
            <li></li>
            <li></li>
            <li></li>
            <li>I look at this. I generate. - <a href="https://twitter.com/Edgar_DOP" target="_blank">Edgar Dubrovskiy</a></li>
          </ul>
          <h1>What do the cards do?</h1>
          <ul className="bulletless-noindent">
            <li><span className="shape-color">Shape</span> cards specify a set of shapes that various generative algorithms will manipulate.</li>
            <li> <span className="op-color">Algorithm</span> cards are used to specify different generative algorithms.</li>
            <li><span className="color-color">Color</span> cards apply a color pallete.</li>
          </ul>
          <h1>How will the cards be distributed?</h1>
          <p>
            <span className="op-color">Once again we are combining two batches (i.e. 2000 cards), which will be distributed via pre-sale and the final sale which opens {sale_date}.</span>
          </p>
          <p>
            Cards will be distributed in 5 batches of 1000. The first batch will be released at 6pm GMT on October 1st 2021. Each of these batches will contain the same amounts of each type of card. As a result, the chance of getting a particular card is the same in each batch. For number-lovers, this means that the supply for each card is a multiple of 5.
          </p>
          <p>
            Cards will be minted <a href="https://www.hicetnunc.xyz/artcardz/creations" target="blank_">here</a> on hicetnunc. Using a smart contract, the distribution of these cards is randomized. The OBJKTS will appear in your wallet immediately after the transaction is confirmed on the blockchain. Since cards are distributed randomly, you may have to trade with others to get the cards you want. This can be done on <a href="https://www.hicetnunc.xyz/" target="blank_">hicetnunc.xyz</a> or <a href="http://objkt.com/" target="blank_">objkt.com</a> as per usual.
          </p>
          <h1>What about pricing?</h1>
          <ul className="bulletless-noindent">
            <li>
              The next batch released at will be priced as follows:
            </li>
            <li>
              1 Card - {price_data[1]} Tez
            </li>
            <li>
              4 Cards - {price_data[4]} Tez
            </li>
            <li>
              8 Cards - {price_data[8]} Tez
            </li>
          </ul>
          <h1>How many cards can be collected at once?</h1>
          <p>
            Cards can be bought in packs of 1, 4, or 8
          </p>
          <h1>How do I mint some artwork?</h1>
          <p>
            Sets of three cards (<span className="shape-color">Shape</span>,<span className="op-color">Algorithm</span>,<span className="color-color">Color</span>) or four cards (<span className="shape-color">Shape</span>,<span className="op-color">Algorithm</span>,<span className="op-color">Algorithm</span>,<span className="color-color">Color</span>) can be used to mint a unique 1/1 artwork. You will be able to mint artworks on this site using your cards after all the cards have been distributed.
          </p>
          <h1>How many cards are there?</h1>
          <ul className="bulletless-noindent">
            <li>
              1250 - <span className="shape-color">Shape</span> Cards
            </li>
            <li>
              2500 - <span className="op-color">Algorithm</span> Cards
            </li>
            <li>
              1250 - <span className="color-color">Color</span> Cards
            </li>
            <li>
              This means that a total of 1250 1/1 artworks can be minted.
            </li>
          </ul>
          <h1>Are some cards more rare than others?</h1>
          {supply_table}
          <h1>Where can the cards be traded?</h1>
          <p>
            On <a href="https://www.hicetnunc.xyz/" target="blank_">hicetnunc.xyz</a> or <a href="http://objkt.com/" target="blank_">objkt.com</a> as you see fit!
          </p>
          <h1>How do you ensure a fair distribution of the cards?</h1>
          <p>
            The <a href={link} target="blank_">contract</a> uses the <a href="https://harbinger.live/" target="blank_">Harbinger Price Oracle</a> to randomize the distribution of cards.
          </p>
          <h1>What do the mystery cards do?</h1>
          <p>
            Telling you would spoil the whole point.
          </p>
          <h1 onClick={activate_admin_mode}>More questions?</h1>
          <p>
            Feel free to contact me on <a href="https://twitter.com/landlinesart1" target="blank_">twitter</a>.
          </p>          
        </div>
      </div>
      <div className="main-box"></div>
    </div>
    
     
  );
  
};

export default App;
