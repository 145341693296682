export const supply_data = [
    {
        "title": "TRIANGLES ",
        "type": "SHAPE",
        "supply": 10,
        "order": 0
    },
    {
        "title": "ARCS ",
        "type": "SHAPE",
        "supply": 35,
        "order": 0
    },
    {
        "title": "RECTANGLE GRID SMALL ",
        "type": "SHAPE",
        "supply": 60,
        "order": 0
    },
    {
        "title": "CIRCLE GRID SMALL ",
        "type": "SHAPE",
        "supply": 85,
        "order": 0
    },
    {
        "title": "SEMICIRCLES ",
        "type": "SHAPE",
        "supply": 110,
        "order": 0
    },
    {
        "title": "SQUARE ",
        "type": "SHAPE",
        "supply": 140,
        "order": 0
    },
    {
        "title": "RECTANGLE GRID LARGE ",
        "type": "SHAPE",
        "supply": 165,
        "order": 0
    },
    {
        "title": "CIRCLE GRID LARGE ",
        "type": "SHAPE",
        "supply": 190,
        "order": 0
    },
    {
        "title": "SQUARES ",
        "type": "SHAPE",
        "supply": 215,
        "order": 0
    },
    {
        "title": "CIRCLES ",
        "type": "SHAPE",
        "supply": 240,
        "order": 0
    },
    {
        "title": "MYSTERY ",
        "type": "ALGORITHM",
        "supply": 5,
        "order": 1
    },
    {
        "title": "CURVE SLICE + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 10,
        "order": 1
    },
    {
        "title": "CURVE SLICE + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 10,
        "order": 1
    },
    {
        "title": "CURVE SLICE + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 15,
        "order": 1
    },
    {
        "title": "CURVE SLICE + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 20,
        "order": 1
    },
    {
        "title": "CURVE SLICE ",
        "type": "ALGORITHM",
        "supply": 20,
        "order": 1
    },
    {
        "title": "CIRCLE SPIN + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 25,
        "order": 1
    },
    {
        "title": "CIRCLE SPIN + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 30,
        "order": 1
    },
    {
        "title": "CIRCLE SPIN + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 35,
        "order": 1
    },
    {
        "title": "CIRCLE SPIN + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 35,
        "order": 1
    },
    {
        "title": "CIRCLE SPIN ",
        "type": "ALGORITHM",
        "supply": 40,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE LEFT + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 45,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE LEFT + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 45,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE LEFT + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 50,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE LEFT + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 55,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE LEFT ",
        "type": "ALGORITHM",
        "supply": 60,
        "order": 1
    },
    {
        "title": "RECTANGLE SPIN + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 60,
        "order": 1
    },
    {
        "title": "RECTANGLE SPIN + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 65,
        "order": 1
    },
    {
        "title": "RECTANGLE SPIN + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 70,
        "order": 1
    },
    {
        "title": "RECTANGLE SPIN + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 75,
        "order": 1
    },
    {
        "title": "RECTANGLE SPIN ",
        "type": "ALGORITHM",
        "supply": 80,
        "order": 1
    },
    {
        "title": "HORIZONTAL SLICE + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 85,
        "order": 1
    },
    {
        "title": "HORIZONTAL SLICE + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 90,
        "order": 1
    },
    {
        "title": "HORIZONTAL SLICE + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 90,
        "order": 1
    },
    {
        "title": "HORIZONTAL SLICE + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 95,
        "order": 1
    },
    {
        "title": "HORIZONTAL SLICE ",
        "type": "ALGORITHM",
        "supply": 100,
        "order": 1
    },
    {
        "title": "VERTICAL SLICE + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 105,
        "order": 1
    },
    {
        "title": "VERTICAL SLICE + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 105,
        "order": 1
    },
    {
        "title": "VERTICAL SLICE + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 110,
        "order": 1
    },
    {
        "title": "VERTICAL SLICE + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 115,
        "order": 1
    },
    {
        "title": "VERTICAL SLICE ",
        "type": "ALGORITHM",
        "supply": 115,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE RIGHT + RANDOM SUBSET ",
        "type": "ALGORITHM",
        "supply": 120,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE RIGHT + RANDOM ROTATE ",
        "type": "ALGORITHM",
        "supply": 125,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE RIGHT + RANDOM TRANSLATE ",
        "type": "ALGORITHM",
        "supply": 130,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE RIGHT + RANDOM SCALE ",
        "type": "ALGORITHM",
        "supply": 130,
        "order": 1
    },
    {
        "title": "DIAGONAL SLICE RIGHT ",
        "type": "ALGORITHM",
        "supply": 135,
        "order": 1
    },
    {
        "title": "PASTEL GREEN ",
        "type": "COLOR",
        "supply": 10,
        "order": 2
    },
    {
        "title": "COOLTONE DARK ",
        "type": "COLOR",
        "supply": 15,
        "order": 2
    },
    {
        "title": "GREYS ",
        "type": "COLOR",
        "supply": 30,
        "order": 2
    },
    {
        "title": "PASTEL RED ",
        "type": "COLOR",
        "supply": 50,
        "order": 2
    },
    {
        "title": "PASTEL YELLOW ",
        "type": "COLOR",
        "supply": 85,
        "order": 2
    },
    {
        "title": "PASTEL DARK BLUE ",
        "type": "COLOR",
        "supply": 130,
        "order": 2
    },
    {
        "title": "PASTEL BLUE ",
        "type": "COLOR",
        "supply": 180,
        "order": 2
    },
    {
        "title": "COOLTONE PINK ",
        "type": "COLOR",
        "supply": 225,
        "order": 2
    },
    {
        "title": "PASTEL ",
        "type": "COLOR",
        "supply": 255,
        "order": 2
    },
    {
        "title": "COOLTONE ",
        "type": "COLOR",
        "supply": 270,
        "order": 2
    }
];
    