export const token_to_name = {
  "377957": "mystery",
  "377997": "triangles",
  "378001": "arcs",
  "378009": "rectangle_grid_small",
  "378015": "circle_grid_small",
  "378026": "semicircles",
  "378029": "square",
  "378034": "rectangle_grid_large",
  "378037": "circle_grid_large",
  "378041": "squares",
  "378045": "circles",
  "378075": "curve_slice_plus_random_subset",
  "378094": "curve_slice_plus_random_scale",
  "378079": "curve_slice_plus_random_rotate",
  "378087": "curve_slice_plus_random_translate",
  "378102": "curve_slice",
  "378105": "circle_spin_plus_random_subset",
  "378112": "circle_spin_plus_random_rotate",
  "378120": "circle_spin_plus_random_translate",
  "378157": "circle_spin_plus_random_scale",
  "378163": "circle_spin",
  "378166": "diagonal_slice_left_plus_random_subset",
  "378172": "diagonal_slice_left_plus_random_rotate",
  "378175": "diagonal_slice_left_plus_random_translate",
  "378183": "diagonal_slice_left_plus_random_scale",
  "378188": "diagonal_slice_left",
  "378195": "rectangle_spin_plus_random_subset",
  "378249": "rectangle_spin_plus_random_rotate",
  "378270": "rectangle_spin_plus_random_translate",
  "378294": "rectangle_spin_plus_random_scale",
  "378301": "rectangle_spin",
  "378313": "horizontal_slice_plus_random_subset",
  "378319": "horizontal_slice_plus_random_rotate",
  "378344": "horizontal_slice_plus_random_translate",
  "378361": "horizontal_slice_plus_random_scale",
  "378370": "horizontal_slice",
  "378377": "vertical_slice_plus_random_subset",
  "378385": "vertical_slice_plus_random_rotate",
  "378396": "vertical_slice_plus_random_translate",
  "378405": "vertical_slice_plus_random_scale",
  "378409": "vertical_slice",
  "378417": "diagonal_slice_right_plus_random_subset",
  "378422": "diagonal_slice_right_plus_random_rotate",
  "378431": "diagonal_slice_right_plus_random_translate",
  "378435": "diagonal_slice_right_plus_random_scale",
  "378437": "diagonal_slice_right",
  "378507": "pastel_dark_blue",
  "378490": "cooltone_dark",
  "378484": "pastel_green",
  "378495": "greys",
  "378498": "pastel_red",
  "378501": "pastel_yellow",
  "378514": "pastel_blue",
  "378518": "cooltone_pink",
  "378528": "pastel",
  "378533": "cooltone"
};

export const name_to_token = {
  "mystery": 377957,
  "triangles": 377997,
  "arcs": 378001,
  "rectangle_grid_small": 378009,
  "circle_grid_small": 378015,
  "semicircles": 378026,
  "square": 378029,
  "rectangle_grid_large": 378034,
  "circle_grid_large": 378037,
  "squares": 378041,
  "circles": 378045,
  "curve_slice_plus_random_subset": 378075,
  "curve_slice_plus_random_scale": 378094,
  "curve_slice_plus_random_rotate": 378079,
  "curve_slice_plus_random_translate": 378087,
  "curve_slice": 378102,
  "circle_spin_plus_random_subset": 378105,
  "circle_spin_plus_random_rotate": 378112,
  "circle_spin_plus_random_translate": 378120,
  "circle_spin_plus_random_scale": 378157,
  "circle_spin": 378163,
  "diagonal_slice_left_plus_random_subset": 378166,
  "diagonal_slice_left_plus_random_rotate": 378172,
  "diagonal_slice_left_plus_random_translate": 378175,
  "diagonal_slice_left_plus_random_scale": 378183,
  "diagonal_slice_left": 378188,
  "rectangle_spin_plus_random_subset": 378195,
  "rectangle_spin_plus_random_rotate": 378249,
  "rectangle_spin_plus_random_translate": 378270,
  "rectangle_spin_plus_random_scale": 378294,
  "rectangle_spin": 378301,
  "horizontal_slice_plus_random_subset": 378313,
  "horizontal_slice_plus_random_rotate": 378319,
  "horizontal_slice_plus_random_translate": 378344,
  "horizontal_slice_plus_random_scale": 378361,
  "horizontal_slice": 378370,
  "vertical_slice_plus_random_subset": 378377,
  "vertical_slice_plus_random_rotate": 378385,
  "vertical_slice_plus_random_translate": 378396,
  "vertical_slice_plus_random_scale": 378405,
  "vertical_slice": 378409,
  "diagonal_slice_right_plus_random_subset": 378417,
  "diagonal_slice_right_plus_random_rotate": 378422,
  "diagonal_slice_right_plus_random_translate": 378431,
  "diagonal_slice_right_plus_random_scale": 378435,
  "diagonal_slice_right": 378437,
  "pastel_dark_blue": 378507,
  "cooltone_dark": 378490,
  "pastel_green": 378484,
  "greys": 378495,
  "pastel_red": 378498,
  "pastel_yellow": 378501,
  "pastel_blue": 378514,
  "cooltone_pink": 378518,
  "pastel": 378528,
  "cooltone": 378533
};