import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import {
  NetworkType,
  BeaconEvent,
  defaultEventCallbacks
} from "@airgap/beacon-sdk";

type ButtonProps = {
  Tezos: TezosToolkit;
  setWallet: Dispatch<SetStateAction<any>>;
  setBeaconConnection: Dispatch<SetStateAction<boolean>>;
  beaconConnection: boolean;
  setUserAddress: Dispatch<SetStateAction<any>>;
  wallet: BeaconWallet;
  setTezos: Dispatch<SetStateAction<TezosToolkit>>;
  rpcUrl: string;
  networkType: NetworkType;
  contract: string;
};

const ConnectButton = ({
  Tezos,
  setWallet,
  setBeaconConnection,
  beaconConnection,
  setUserAddress,
  wallet,
  setTezos,
  rpcUrl,
  networkType,
  contract
}: ButtonProps): JSX.Element => {
  const [synced, setSynced] = useState<boolean>(false);

  const setup = async (wallet : BeaconWallet): Promise<void> => {
    await wallet.requestPermissions({
      network: {
        type: networkType,
        rpcUrl: rpcUrl
      }
    });
    const userAddress = await wallet.getPKH();
    setUserAddress(userAddress);
    setBeaconConnection(true);
  };

  const connectWallet = async (): Promise<void> => {

    try {

      /*
      if (wallet == null) {
        console.log("wallet is null");
        // only do this once
        const wallet = new BeaconWallet({
          name: "ArtCardz",
          preferredNetwork: networkType,
          disableDefaultEvents: true, // Disable all events / UI. This also disables the pairing alert.
          eventHandlers: {
            // To keep the pairing alert, we have to add the following default event handlers back
            [BeaconEvent.PAIR_INIT]: {
              handler: defaultEventCallbacks.PAIR_INIT
            },
            [BeaconEvent.PAIR_SUCCESS]: {
              handler: data => setBeaconConnection(true)
            }
          }
        });
        Tezos.setWalletProvider(wallet);
        setWallet(wallet);
        
      }
      */

      const active = await wallet.client.getActiveAccount();
      if (active === undefined) {
        await wallet.requestPermissions({
          type: networkType,
          rpcUrl: rpcUrl
        });
      }
      
      const userAddress = await wallet.getPKH();
      setUserAddress(userAddress);
      setBeaconConnection(true);
      
    } catch (error) {
      console.log(error);
    }
  };

  const disconnectWallet = async (): Promise<void> => {
    await wallet.client.clearActiveAccount();
    setBeaconConnection(false);
    setUserAddress(null);

    /*
    const tezosTK = new TezosToolkit(rpcUrl);
    setTezos(tezosTK);
    setBeaconConnection(false);
    console.log("disconnecting wallet");
    */
  };

  console.log(beaconConnection);

  if (!beaconConnection) {
    return (
      <div className="buttons">
        <button className="button" onClick={connectWallet}>
          <span>
            sync
          </span>
        </button>
      </div>
    );
  }
  return (
    <div className="buttons">
      <button className="button" onClick={disconnectWallet}>
        unsync
      </button>
    </div>
  );
};

export default ConnectButton;
