export const codebook = {
  "circles": "0",
  "diagonal_slice_right_plus_random_translate": "1",
  "diagonal_slice_right": "2",
  "greys": "3",
  "triangles": "4",
  "diagonal_slice_right_plus_random_subset": "5",
  "diagonal_slice_left_plus_random_scale": "6",
  "rectangle_spin_plus_random_translate": "7",
  "curve_slice": "8",
  "semicircles": "9",
  "rectangle_spin_plus_random_scale": "10",
  "horizontal_slice_plus_random_rotate": "11",
  "circle_grid_large": "12",
  "vertical_slice_plus_random_subset": "13",
  "curve_slice_plus_random_translate": "14",
  "arcs": "15",
  "vertical_slice_plus_random_scale": "16",
  "vertical_slice_plus_random_translate": "17",
  "square": "18",
  "diagonal_slice_right_plus_random_scale": "19",
  "circle_spin_plus_random_rotate": "20",
  "curve_slice_plus_random_subset": "21",
  "horizontal_slice": "22",
  "diagonal_slice_left_plus_random_rotate": "23",
  "circle_grid_small": "24",
  "rectangle_grid_small": "25",
  "circle_spin_plus_random_translate": "26",
  "curve_slice_plus_random_rotate": "27",
  "squares": "28",
  "diagonal_slice_left_plus_random_subset": "29",
  "circle_spin_plus_random_subset": "30",
  "curve_slice_plus_random_scale": "31",
  "rectangle_spin_plus_random_rotate": "32",
  "horizontal_slice_plus_random_translate": "33",
  "rectangle_spin": "34",
  "horizontal_slice_plus_random_subset": "35",
  "diagonal_slice_left_plus_random_translate": "36",
  "vertical_slice": "37",
  "mystery": "38",
  "diagonal_slice_left": "39",
  "rectangle_grid_large": "40",
  "vertical_slice_plus_random_rotate": "41",
  "circle_spin": "42",
  "diagonal_slice_right_plus_random_rotate": "43",
  "rectangle_spin_plus_random_subset": "44",
  "horizontal_slice_plus_random_scale": "45",
  "no_card": "46",
  "circle_spin_plus_random_scale": "47",
  "pastel_dark_blue": "48",
  "cooltone_dark": "49",
  "pastel_yellow": "50",
  "cooltone_pink": "51",
  "cooltone": "52",
  "pastel_blue": "53",
  "pastel_green": "54",
  "pastel_red": "55",
  "pastel": "56"
};