export const card_data = {
    "base": [
        "arcs",
        "circle_grid_large",
        "circle_grid_small",
        "circles",
        "rectangle_grid_large",
        "rectangle_grid_small",
        "semicircles",
        "square",
        "squares",
        "triangles"
    ],
    "ops": [
        "circle_spin",
        "circle_spin_plus_random_rotate",
        "circle_spin_plus_random_scale",
        "circle_spin_plus_random_subset",
        "circle_spin_plus_random_translate",
        "curve_slice",
        "curve_slice_plus_random_rotate",
        "curve_slice_plus_random_scale",
        "curve_slice_plus_random_subset",
        "curve_slice_plus_random_translate",
        "diagonal_slice_left",
        "diagonal_slice_left_plus_random_rotate",
        "diagonal_slice_left_plus_random_scale",
        "diagonal_slice_left_plus_random_subset",
        "diagonal_slice_left_plus_random_translate",
        "diagonal_slice_right",
        "diagonal_slice_right_plus_random_rotate",
        "diagonal_slice_right_plus_random_scale",
        "diagonal_slice_right_plus_random_subset",
        "diagonal_slice_right_plus_random_translate",
        "horizontal_slice",
        "horizontal_slice_plus_random_rotate",
        "horizontal_slice_plus_random_scale",
        "horizontal_slice_plus_random_subset",
        "horizontal_slice_plus_random_translate",
        "no_card",
        "rectangle_spin",
        "rectangle_spin_plus_random_rotate",
        "rectangle_spin_plus_random_scale",
        "rectangle_spin_plus_random_subset",
        "rectangle_spin_plus_random_translate",
        "vertical_slice",
        "vertical_slice_plus_random_rotate",
        "vertical_slice_plus_random_scale",
        "vertical_slice_plus_random_subset",
        "vertical_slice_plus_random_translate"
    ],
    "color": [
        "cooltone",
        "cooltone_dark",
        "cooltone_pink",
        "greys",
        "pastel",
        "pastel_blue",
        "pastel_dark_blue",
        "pastel_green",
        "pastel_red",
        "pastel_yellow"
    ]
};