import React, { useState, Dispatch, SetStateAction } from "react";
import { TezosToolkit, OpKind, MichelsonMap } from "@taquito/taquito";

import { card_data, art_data } from "../data";
import { name_to_token } from "../token_to_name";

interface TransferParams {
  creator: string;
  contract: String;
}

const MintButton = ({
  Tezos,
  selections,
  userTokens,
  setMintMessage,
  objkt_contract,
  contract,
  admin_address,
  adminMode,
}: TransferParams) => {

  var mystery_selections = selections.concat(["mystery"]); 

  const try_mint = async (selections): Promise<void> => {

    // to go live just uncomment
    //setMintMessage("Minting will open at 7PM GMT October 8th.");
    //return;

    var attempt = {};
    for (var i=0; i<selections.length; i++) {
      if (selections[i] != "no_card") {
        if (selections[i] in attempt) {
          attempt[selections[i]] = attempt[selections[i]] + 1;
        }
        else {
          attempt[selections[i]] = 1;
        }
      }
    }
    
    var success = true;
    for (const [key, value] of Object.entries(attempt)) {
      if (value > userTokens[key]) {
        var pretty = key.replace(/_/g," ").replace(" plus "," + ");
        setMintMessage("You have " + userTokens[key].toString() + " " + pretty + " cardz but are trying to use " + value.toString() + "!");
        success = false;
      }
      else if (!(key in userTokens)) {
        var pretty = key.replace(/_/g," ").replace(" plus "," + ");
        setMintMessage("You have 0 " + pretty + " cardz but are trying to use " + value.toString() + "!");
        success = false;
      }
    }

    if (success) {
      var tokens = [];
      for (var i=0; i<selections.length; i++) {
        if (selections[i] != "no_card") {
          tokens.push( name_to_token[selections[i]] );
        }
      }

      if (tokens.length == 2) {
        setMintMessage("Must use atleast 3 cards!");
        return;
      }

      var template = 0;
      if (tokens.length == 4) {
        if (tokens[tokens.length-1] == name_to_token["mystery"]) {
          template = 1;
        }
        else {
          template = 2;
        }
      }
      else if (tokens.length == 5) {
        template = 3;
      }
    }

    ////////////////////////////////////////////
    // warning this section is just for testing
    ///*
    if (adminMode == true) {
      success = true;
      var tokens = [100,100];
      var template = 0;
    }
    
    //*/
    // warning this section is just for testing
    ////////////////////////////////////////////
    if (success) {
      var links = [(<span>WARNING : This operation will burn tokens </span>)];
      for (var i=0; i<tokens.length; i++) {
        var link = "https://www.hicetnunc.xyz/objkt/" + tokens[i].toString();
        var name = tokens[i].toString();
        links.push( (<a href={link} target="_blank">{name}</a>) );
        links.push( (<span> </span>) );
      }
      setMintMessage((<span>{links}</span>));

      try {

        console.log(tokens, template);
        
        let c = await Tezos.wallet.at(contract);
        let objkts = await Tezos.wallet.at(objkt_contract);

        let list = [];
        var operators = [];
        var remove_operators = [];
        for (var i=0; i<tokens.length; i++) {
          operators.push( { add_operator: { operator: contract, token_id: tokens[i], owner: admin_address } } );
          remove_operators.push( {remove_operator: { operator: contract, token_id: tokens[i], owner: admin_address } } );
        }

        list.push({
          kind: OpKind.TRANSACTION,
          ...objkts.methods.update_operators(operators)
            .toTransferParams({ amount: 0, mutez: true, storageLimit: 100*operators.length })
        });

        list.push({
          kind: OpKind.TRANSACTION,
          ...c.methods.mint(objkt_contract, template, tokens).toTransferParams({ amount: 250000, mutez: true, storageLimit: 500 })
        });

        list.push({
          kind: OpKind.TRANSACTION,
          ...objkts.methods.update_operators(remove_operators)
            .toTransferParams({ amount: 0, mutez: true, storageLimit: 100*remove_operators.length })
        });

        let batch = await Tezos.wallet.batch(list);
        const op = await batch.send();
        await op.confirmation();
        
      } catch (error) {
        console.log(error);
      }

    }
  }

  return (
    <div>
      <button className="thin-button" onClick={() => try_mint(selections)}>
        <span>
          Mint
        </span>
      </button>
      <button className="thin-button" onClick={() => try_mint(mystery_selections)}>
        <span>
          Mystery Mint
        </span>
      </button>
    </div>
    
  );
};


export default MintButton;
